<template>
    <transition name="slide-down">
        <li v-if="timeout">
            <div :class="getClasses" class="rounded-md -mt-4 p-4 ccss--modal-user-notification-message notification">
                <div class="flex">
                    <div class="flex-shrink-0 flex items-center">
                        <!-- success -->
                        <svg
                            v-if="notification.content.type == 'success'"
                            class="h-5 w-5 text-green-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                            />
                        </svg>

                        <!-- warning -->
                        <svg
                            v-else-if="notification.content.type == 'warning'"
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5 text-yellow-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                        </svg>

                        <!-- error -->
                        <svg
                            v-else-if="notification.content.type == 'error'"
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5 text-red-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                        >
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <!-- info -->
                        <svg
                            v-else
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5 text-blue-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                        >
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <p class="text-sm font-medium">{{ $t(notification.content.message) }}</p>
                    </div>
                    <div class="ml-auto pl-3">
                        <div class="-mx-1.5 -my-1.5">
                            <button @click="this.removeNotification(notification.id)" type="button" class="inline-flex rounded-md p-1.5">
                                <span class="sr-only">Dismiss</span>
                                <svg
                                    class="h-5 w-5 transition duration-300 hover:opacity-50"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </transition>
</template>

<script>
// import * as _state from "../lib/state";
import * as _notification from '../lib/notification';

export default {
    props: ['notification'],
    data() {
        return {
            timeout: null,
        };
    },
    mounted() {
        this.created();
    },

    methods: {
        created() {
            this.timeout = setTimeout(() => {
                this.removeNotification(this.notification.id);
            }, 3000);
        },

        removeNotification(id) {
            this.clearTimer();
            _notification.remove(id);
        },

        clearTimer() {
            clearTimeout(this.timeout);
        },
    },
    computed: {
        getClasses() {
            if (this.notification.content.type === 'success') {
                return 'bg-green-50 text-green-800';
            }

            if (this.notification.content.type === 'error') {
                return 'bg-red-50 text-red-800';
            }

            if (this.notification.content.type === 'warning') {
                return 'bg-yellow-50 text-yellow-800';
            }

            return 'bg-blue-50 text-blue-800';
        },
    },
};
</script>
