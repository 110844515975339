import md5 from 'js-md5';
import * as _log from './log';
import * as _api from './api';
import * as _state from './state';
import * as _transaction from './transaction';

// set the logger
const logger = _log.get('APPLICATION STATE');

const init = () => {
    logger.log('init')();

    const applicationState = _state.get('applicationState/getApplicationState');

    // todo: check if active
    // todo: add functions
    _state.set('applicationState/setApplicationState', {
        hash: applicationState.hash || false,
        active: true,
        loading: false,
        delivery_type: false,
        routeFrom: applicationState.routeFrom,
        routeTo: false,
        startpage: 'landing',
        lockerFlowStep: applicationState.lockerFlowStep || 4,
        lockerFlowTotalSteps: applicationState.lockerFlowTotalSteps || 12,
        authentication_required: false,
        basket_start_time: applicationState.basket_start_time || null,
        hours_difference: applicationState.hours_difference || null,
        functions: {
            delivery: false,
            quickorder: false,
            tables: false,
            menu: false,
            elockers: false,
            reservation: false,
            visitors: false,
            wallet: false,
        },
    });
};

const isWebshopActive = async () => {
    const response = await _api.get('active');

    if (response && response.active) {
        return response.active;
    }
    return false;
};

const canAccessFunction = (access) => {
    const functions = getFunctions();

    return functions[access] ? functions[access] : false;
};

// getters
const getFunctions = () => {
    return _state.get('applicationState/getApplicationState')['functions'];
};

const getStartPage = () => {
    return _state.get('applicationState/getApplicationState')['startpage'];
};

const getLockerFlowStep = () => {
    return _state.get('applicationState/getApplicationState')['lockerFlowStep'];
};

const getBasketStartTime = () => {
    const startTime = _state.get('applicationState/getApplicationState')['basket_start_time'];

    if (!startTime) {
        const currentTime = new Date();
        setBasketStartTime(currentTime);
        return currentTime;
    }

    return startTime;
}

const getHoursDifference = () => {
    return _state.get('applicationState/getApplicationState')['hours_difference'];
}

const getLockerFlowTotalSteps = () => {
    return _state.get('applicationState/getApplicationState')['lockerFlowTotalSteps'];
};

const isAuthenticationRequired = () => {
    return _state.get('applicationState/getApplicationState')['authentication_required'];
};

const isLoading = () => {
    return _state.get('applicationState/getApplicationState')['loading'];
};

const userLoggedIn = () => {
    if (_state.get('user/getUser').authenticated === true) {
        return true;
    }

    return false;
};

// setters
const setFunctions = (functions) => {
    _state.setField('applicationState/getApplicationState', 'applicationState/setApplicationState', 'functions', {
        delivery: functions.delivery ? functions.delivery : false,
        takeaway: functions.takeaway ? functions.takeaway : false,
        quickorder: functions.quickorder ? functions.quickorder : false,
        tables: functions.tables ? functions.tables : false,
        menu: functions.menu ? functions.menu : false,
        elockers: functions.elockers ? functions.elockers : false,
        reservation: functions.reservation ? functions.reservation : false,
        visitors: functions.visitors ? functions.visitors : false,
        wallet: functions.wallet ? functions.wallet : false,
    });
};

const setHash = (locationSlug, method) => {
    const hash = md5(`${window.location.hostname}:${locationSlug}:${method}`);

    let applicationState = _state.get('applicationState/getApplicationState');

    if (applicationState.hash !== hash) {
        _transaction.clear(true);
        applicationState.hash = hash;
        _state.set('applicationState/setApplicationState', applicationState);
    }
};

const setRoutes = (from, to) => {
    if (from && from != 'paymentstatus') {
        _state.setField('applicationState/getApplicationState', 'applicationState/setApplicationState', 'routeFrom', from);
    }
    _state.setField('applicationState/getApplicationState', 'applicationState/setApplicationState', 'routeTo', to);
};

const setStartPage = (routename) => {
    _state.setField('applicationState/getApplicationState', 'applicationState/setApplicationState', 'startpage', routename);
};

const setLockerFlowStep = (routename) => {
    _state.setField('applicationState/getApplicationState', 'applicationState/setApplicationState', 'lockerFlowStep', routename);
};

const setLockerFlowTotalSteps = (routename) => {
    _state.setField('applicationState/getApplicationState', 'applicationState/setApplicationState', 'lockerFlowTotalSteps', routename);
};

const setBasketStartTime = (time) => {
    _state.setField('applicationState/getApplicationState', 'applicationState/setApplicationState', 'basket_start_time', time);
};

const setHoursDifference = (amount = null) => {
    _state.setField('applicationState/getApplicationState', 'applicationState/setApplicationState', 'hours_difference', amount);
};

const setLoading = (state) => {
    _state.setField('applicationState/getApplicationState', 'applicationState/setApplicationState', 'loading', state);
};

const setAuthenticationRequired = (authentication_required) => {
    _state.setField(
        'applicationState/getApplicationState',
        'applicationState/setApplicationState',
        'authentication_required',
        authentication_required
    );
};

export {
    init,
    canAccessFunction,
    isWebshopActive,
    getFunctions,
    getStartPage,
    getLockerFlowStep,
    getLockerFlowTotalSteps,
    getBasketStartTime,
    getHoursDifference,
    userLoggedIn,
    setStartPage,
    setLockerFlowStep,
    setLockerFlowTotalSteps,
    isAuthenticationRequired,
    setAuthenticationRequired,
    setRoutes,
    setFunctions,
    setBasketStartTime,
    setHoursDifference,
    setHash,
    isLoading,
    setLoading,
};
