import { createApp } from 'vue';
// import { createI18n } from 'vue-i18n';
import { createI18n } from 'vue-i18n/index';

import axios from 'axios';
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';

import App from './App.vue';
import router from './router';
import states from './states';
import './main.css';

import { getMessages, getNumberFormats } from './lib/i18n';

let messages = getMessages();
let numberFormats = getNumberFormats();

export const i18n = createI18n({
    legacy: false,
    locale: 'nl',
    messages,
    numberFormats,
    warnHtmlMessage: false, // disable warning HTML in message
});

// axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Source'] = 'webshop';
axios.defaults.headers.common['Domain'] = window.location.host;

const token = localStorage.getItem('auth.token');
if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}
createApp(App)
    .use(states)
    .use(i18n)
    .use(router)
    .use(VCalendar)
    // .use(VueScrollTo)
    .mount('#app');
